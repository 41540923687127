import React from 'react'
import Meta from '../components/Meta'
import HeroHome from '../components/HeroHome'
import About from '../components/About'
import Who from '../components/Who'
import BottomImage from '../components/BottomImage'
import NewsSlider from '../components/NewsSlider'
import { useHome } from '../hooks/useHome'

const IndexPage = () => {
	const { seo, _rawBottomImage, quoteSlider } = useHome()

	return (
		<>
			<Meta pageTitle={seo?.title || 'Home'} />
			<HeroHome />
			<About />
			<Who />
			{quoteSlider && <NewsSlider type={quoteSlider._type} slides={quoteSlider.quotes} />}
			<BottomImage {..._rawBottomImage} />
		</>
	)
}

export default IndexPage
