import React from 'react'
import { ArrowDown, ArrowDownShort, Badge } from './Icons'
import { useMedia } from 'react-use'
import { useHome } from '../hooks/useHome'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import SanityImage from './SanityImage'
import { useImageSize } from "../hooks/useImageSize"

const HeroHome = () => {
	const { heroHome } = useHome()
	const isSmall = useMedia('(min-width: 768px)')
	const imageWidth = useImageSize(0.5868, null, 'xl')
	const { headlineTop, headlineBottom, subHeadlineTop, subHeadlineBottom, _rawDesktopImage } = heroHome

	return (
		<section id='heroHome'>
			<div className='flex flex-col-reverse h-screen bg-tertiary overflow-hidden relative stagger-container md:flex-row'>
				<div className='pb-10 flex flex-col md:header-padding md:justify-center md:pl-[5%] md:pr-[2%]'>
					<h1 className='px-[6%] whitespace-nowrap md:px-0'>
						<span className='h1 block'>{headlineTop}</span>
						{headlineBottom ? <span className='block h3'>{headlineBottom}</span> : null}
					</h1>
					<div>
						<div className='flex md:block'>
							<ArrowDownShort className='block md:hidden w-2/5' />
							<h2 className='-rotate-6 self-end mx-auto max-w-max md:mr-auto md:ml-0 md:self-start md:pt-8 md:pb-2'>
								<AnchorLink to={'/#about'}>
									<span className='h2-alt block'>{subHeadlineTop}</span>
									{subHeadlineBottom ? <span className='block h2-alt pl-12'>{subHeadlineBottom}</span> : null}
								</AnchorLink>
							</h2>
						</div>
						<ArrowDown className='hidden md:block md:w-3/4' />
					</div>
				</div>
				<div
					className='header-translate flex-1'
					style={{ width: isSmall && imageWidth }}
				>
					<SanityImage
						loading='eager'
						className='pl-[33vw] object-cover object-left-top ml-auto w-full h-full md:pl-0 2xl:h-auto 2xl:object-contain 2xl:pr-5'
						image={_rawDesktopImage}
						maxWidth={imageWidth}
					/>
				</div>
				<Badge className='absolute h-auto w-32 top-[20vh] left-[10vw] md:top-auto md:left-auto md:bottom-0 md:right-0 md:m-[2%] md:w-[13vw]' />
			</div>
		</section>
	)
}

export default HeroHome
