import React, { useRef } from "react"
import { useHome } from '../hooks/useHome'
import cx from 'clsx'
import { useInView } from "framer-motion/dist/framer-motion"
import SanityImage from './SanityImage'
import { useImageSize } from "../hooks/useImageSize"

const WhoTop = () => {
	const { who } = useHome()
	const imageWidth = useImageSize(0.78)

	const {
		whoHeadlineTop,
		whoHeadlineBottom,
		_rawWhoImageOne,
		whoTextOne,
	} = who

	const viewRef = useRef(null)
	const inView = useInView(viewRef, { amount: 0.3, once: true })

	return (
		<section id='whoTop' ref={viewRef}>
			<div className={cx(
				'pb-4 md:pt-[7%] md:flex md:pb-0 md:min-h-max',
				inView ? 'stagger-container' : 'opacity-0'
			)}
			>
				<div className='overflow-x-clip md:overflow-x-visible md:flex md:flex-col md:justify-center md:items-center md:space-y-14'>
					<h2 className='who-headline'>
						<span className='block'>{whoHeadlineTop}</span>
						<span className='block'>{whoHeadlineBottom}</span>
					</h2>
					<p className='hidden regular md:block md:max-w-3xl md:px-10'>{whoTextOne}</p>
				</div>
				<div className='md:max-w-[35%] md:my-auto md:mx-auto'>
					<SanityImage
						viewportFadeIn
						loading='lazy'
						className='px-[15%] mx-auto pb-4 md:p-0 w-min'
						image={_rawWhoImageOne}
						maxWidth={imageWidth}
					/>
				</div>
				<p className='regular px-10 md:hidden'>{whoTextOne}</p>
			</div>
		</section>
	);
}

export default WhoTop;