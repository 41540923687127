import React from "react"
import WhoTop from "./WhoTop"
import WhoBottom from './WhoBottom'

const Who = () => {

	return (
		<section id='homeMain'>
			<WhoTop />
			<WhoBottom />
		</section>
	);
}

export default Who;