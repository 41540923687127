import React, { useRef } from 'react'
import { useHome } from '../hooks/useHome'
import { useInView } from 'framer-motion/dist/framer-motion'
import { Video } from '../blocks/Video'
import cx from 'clsx'

const About = () => {
	const { about } = useHome()
	const viewRef = useRef(null)
	const inView = useInView(viewRef, { amount: 0.4, once: true })
	const { headlineTop, headlineBottom, text, video } = about
	const { vimeoUrl, placeholder } = video

	return (
		<section
			id="about"
			className="bg-white text-black"
			ref={viewRef}
		>
			<div
				className={cx(
					'flex flex-col pb-6 lg:pb-0',
					inView ? 'animate-view' : 'opacity-0'
				)}
			>
				<h2 className='about-header'>
					<span className='block '>{headlineTop}</span>
					{headlineBottom && <span className='block'>{headlineBottom}</span>}
				</h2>
				<div className={cx(
					'relative lg:flex',
					inView && 'stagger-container'
				)}>
					<div className='lg:min-w-[58%]'>
						<Video className='' node={{ vimeoUrl, placeholder }} />
					</div>
					<p className='regular px-[10%] pt-[5%] lg:pt-0 lg:px-[4%]'>{text}</p>
				</div>
			</div>
		</section>
	)
}

export default About