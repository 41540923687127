import React from 'react'
import { PortableText } from './PortableText'
import { CurlyArrow } from './Icons'
import SanityImage from './SanityImage'
import { useHome } from '../hooks/useHome'
import { useImageSize } from '../hooks/useImageSize'

const WhoBottom = () => {
	const { who } = useHome()
	const imageOneWidth = useImageSize(0.4578, 0.73142)
	const imageTwoWidth = useImageSize(0.6192, 0.78)

	const {
		_rawWhoTextTwo,
		_rawWhoImageTwo,
		_rawWhoImageThree,
	} = who

	return (
		<section id='whoBottom'>
			<div className='md:flex md:flex-row-reverse md:pb-[6%]'>
				<div className='flex relative -mb-[5%] md:mb-0 md:flex-col'>
					<CurlyArrow className='absolute h-full w-auto -left-[16vw] rotate-[20deg] top-5 md:hidden' />
					<SanityImage
						loading='lazy'
						viewportFadeIn
						className='w-3/4 ml-auto z-10 md:min-w-[110%] md:-ml-[10%] md:pt-6vw'
						image={_rawWhoImageTwo}
						maxWidth={imageOneWidth}
					/>
					<div className='hidden md:block px-[10%] pt-[10%]'>
						<PortableText className='home rich-text' blocks={_rawWhoTextTwo} />
					</div>
				</div>
				<div className='md:flex md:flex-col md:min-w-[58%]'>
					<CurlyArrow className='hidden md:block md:w-2/3 2xl:w-auto self-end mr-[7%] z-10 -mb-[14vw]' />
					<SanityImage
						loading='lazy'
						viewportFadeIn
						className='w-4/5 mr-auto md:w-full md:pt-2vw xl:pt-8vw'
						image={_rawWhoImageThree}
						maxWidth={imageTwoWidth}
					/>
					<div className='px-10 pt-8 pb-4 md:hidden -z-10'>
						<PortableText className='home rich-text' blocks={_rawWhoTextTwo} />
					</div>
				</div>
			</div>
		</section>
	);
}

export default WhoBottom
